define("ember-svg-jar/inlined/home-button-click", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<rect x=\"5\" y=\"6\" width=\"250\" height=\"100\" rx=\"50\" fill=\"#C3F3FF\"/><rect x=\"5\" y=\"6\" width=\"250\" height=\"100\" rx=\"50\" stroke=\"#00BFEF\" stroke-width=\"10\"/><path d=\"M48.828 69.536V34.32c0-3.1 1.984-4.836 4.65-4.836 2.666 0 4.65 1.736 4.65 4.836v13.144h19.468V34.32c0-3.1 1.984-4.836 4.65-4.836 2.666 0 4.65 1.736 4.65 4.836v35.216c0 3.1-1.984 4.836-4.65 4.836-2.666 0-4.65-1.736-4.65-4.836V56.02H58.128v13.516c0 3.1-1.984 4.836-4.65 4.836-2.666 0-4.65-1.736-4.65-4.836zm59.341-29.264c9.424 0 15.996 8.246 15.996 17.298 0 9.3-6.386 17.174-15.996 17.174S92.173 66.87 92.173 57.57c0-9.052 6.572-17.298 15.996-17.298zm0 8.184c-4.898 0-7.068 4.836-7.068 9.114 0 4.278 2.232 8.99 7.068 8.99 4.836 0 7.068-4.712 7.068-8.99 0-4.278-2.17-9.114-7.068-9.114zm20.997 20.956V45.604c0-2.976 1.86-4.96 4.464-4.96 2.294 0 3.782 1.24 4.092 3.348 2.17-2.356 5.022-3.72 8.308-3.72 3.72 0 6.882 1.55 9.238 4.526 2.48-2.852 6.386-4.526 9.424-4.526 7.254 0 12.214 4.216 12.214 12.028v17.112c0 2.976-1.86 4.96-4.464 4.96s-4.464-1.984-4.464-4.96V53.85c0-3.596-1.984-6.138-5.208-6.138-3.286 0-5.27 2.542-5.27 6.138v15.562c0 2.976-1.86 4.96-4.464 4.96s-4.464-1.984-4.464-4.96V53.85c0-3.596-1.984-6.138-5.208-6.138-3.658 0-5.27 2.542-5.27 6.138v15.562c0 2.976-1.86 4.96-4.464 4.96s-4.464-1.984-4.464-4.96zm80.263-8.928h-19.034c.62 4.402 4.278 6.448 8.432 6.448 4.588 0 7.75-3.596 10.106-3.596 1.922 0 3.658 1.922 3.658 3.844 0 3.844-7.936 7.564-14.57 7.564-10.044 0-16.74-7.316-16.74-17.174 0-9.052 6.572-17.298 15.996-17.298 9.672 0 16.058 8.804 16.058 16.244 0 2.666-1.178 3.968-3.906 3.968zm-19.22-5.952h14.198c-.496-3.906-2.976-6.82-7.13-6.82-3.968 0-6.634 3.038-7.068 6.82z\" fill=\"#6B36BA\"/>",
    "attrs": {
      "width": "260",
      "height": "111",
      "viewBox": "0 0 260 111",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});