define("ember-component-css/instance-initializers/route-styles", ["exports", "ember-component-css/utils/init-route-styles"], function (_exports, _initRouteStyles) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  // This file is removed from the build in Ember < 3.6
  function initialize(appInstance) {
    let router = appInstance.lookup('service:router');
    router.on('routeDidChange', function (_ref) {
      let {
        to
      } = _ref;
      if (likeRouteInfo(to)) {
        (0, _initRouteStyles.default)(appInstance, nestedRouteNames(to));
      }
    });
    router.on('routeWillChange', function (_ref2) {
      let {
        to,
        isActive
      } = _ref2;
      if (likeRouteInfo(to)) {
        if (/_loading$/.test(to.name) && isActive) {
          const routeNames = nestedRouteNames(to)
          // loading route names are set with an _loading even though
          // their path is -loading
          .map(name => name.replace(/_loading$/, '-loading'));
          (0, _initRouteStyles.default)(appInstance, routeNames);
        }
      }
    });
  }
  function nestedRouteNames(_ref3) {
    let {
      name,
      parent
    } = _ref3;
    let routeNames = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
    routeNames.push(name);
    if (parent) {
      return nestedRouteNames(parent, routeNames);
    }
    return routeNames;
  }
  function likeRouteInfo(info) {
    return info && typeof info === 'object' && info.hasOwnProperty('name');
  }
  var _default = _exports.default = {
    initialize
  };
});