define("ember-svg-jar/inlined/button-avatar-my-program", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g filter=\"url(#filter0_d_304_17325)\"><circle cx=\"72\" cy=\"72\" r=\"69.958\" fill=\"#985BE1\" stroke=\"#fff\" stroke-width=\"4\"/><circle cx=\"72\" cy=\"72\" r=\"59.031\" fill=\"#ADB3E9\"/><circle cx=\"72\" cy=\"72\" r=\"60.023\" fill=\"url(#paint0_linear_304_17325)\"/><ellipse cx=\"43.254\" cy=\"26.629\" rx=\"10.639\" ry=\"4.042\" transform=\"rotate(-30.811 43.254 26.629)\" fill=\"#fff\" fill-opacity=\".6\"/><ellipse cx=\"31.544\" cy=\"37.512\" rx=\"4.608\" ry=\"3.365\" transform=\"rotate(-30.811 31.544 37.512)\" fill=\"#fff\" fill-opacity=\".6\"/><path d=\"M68.398 9.058c-13.205 1.289-25.21 6.443-32.412 12.885-12.005 10.308-24.01 28.348-24.01 48.964 37.767 28.347 90.155-17.55 120.047 1.34V70.52C128.421 28.386 98.41 7.77 68.398 9.058z\" fill=\"url(#paint1_linear_304_17325)\" fill-opacity=\".5\"/></g><defs><linearGradient id=\"paint0_linear_304_17325\" x1=\"94.767\" y1=\"11.977\" x2=\"47.853\" y2=\"132.023\" gradientUnits=\"userSpaceOnUse\"><stop stop-color=\"#B4CEFF\"/><stop offset=\"1\" stop-color=\"#B3C2F9\"/></linearGradient><linearGradient id=\"paint1_linear_304_17325\" x1=\"85.613\" y1=\"80.597\" x2=\"85.613\" y2=\"11.738\" gradientUnits=\"userSpaceOnUse\"><stop stop-color=\"#fff\" stop-opacity=\".6\"/><stop offset=\"1\" stop-color=\"#fff\" stop-opacity=\"0\"/></linearGradient><filter id=\"filter0_d_304_17325\" x=\"0\" y=\"0\" width=\"144\" height=\"149\" filterUnits=\"userSpaceOnUse\" color-interpolation-filters=\"sRGB\"><feFlood flood-opacity=\"0\" result=\"BackgroundImageFix\"/><feColorMatrix in=\"SourceAlpha\" values=\"0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0\" result=\"hardAlpha\"/><feOffset dy=\"5\"/><feComposite in2=\"hardAlpha\" operator=\"out\"/><feColorMatrix values=\"0 0 0 0 0.332523 0 0 0 0 0.0604894 0 0 0 0 0.776367 0 0 0 0.8 0\"/><feBlend in2=\"BackgroundImageFix\" result=\"effect1_dropShadow_304_17325\"/><feBlend in=\"SourceGraphic\" in2=\"effect1_dropShadow_304_17325\" result=\"shape\"/></filter></defs>",
    "attrs": {
      "width": "144",
      "height": "149",
      "viewBox": "0 0 144 149",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});