define("ember-svg-jar/inlined/shadow", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<defs><filter id=\"dropshadow\"><feGaussianBlur in=\"SourceAlpha\" stdDeviation=\"3\"/><feOffset dx=\"2\" dy=\"2\" result=\"offsetblur\"/><feComponentTransfer><feFuncA type=\"linear\" slope=\".5\"/></feComponentTransfer><feMerge><feMergeNode/><feMergeNode in=\"SourceGraphic\"/></feMerge></filter></defs>",
    "attrs": {
      "viewBox": "0 0 1 1",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});