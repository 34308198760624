define("ember-svg-jar/inlined/arrow-press", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<filter id=\"dropshadow\"><feGaussianBlur in=\"SourceAlpha\" stdDeviation=\"3\"/><feOffset dx=\"2\" dy=\"2\" result=\"offsetblur\"/><feComponentTransfer><feFuncA type=\"linear\"/></feComponentTransfer><feMerge><feMergeNode/><feMergeNode in=\"SourceGraphic\"/></feMerge></filter><defs><linearGradient id=\"linear-gradient\" x1=\"139.26\" x2=\"139.26\" y2=\"246.37\" gradientUnits=\"userSpaceOnUse\"><stop offset=\"0\" stop-color=\"#f9b14b\"/><stop offset=\"1\" stop-color=\"#eb8a00\"/></linearGradient><linearGradient id=\"linear-gradient-2\" x1=\"139.26\" y1=\"4.12\" x2=\"139.26\" y2=\"242.25\" gradientUnits=\"userSpaceOnUse\"><stop offset=\"0\" stop-color=\"#fff\" stop-opacity=\".4\"/><stop offset=\"1\" stop-color=\"#fff\" stop-opacity=\".6\"/></linearGradient></defs><g filter=\"url(#dropshadow)\"><path d=\"M249.93 37.12h-90.56V17.79A17.76 17.76 0 00130.1 4.27L6.25 109.66a17.76 17.76 0 000 27.05l123.85 105.4a17.76 17.76 0 0029.27-13.53v-19.33h90.56a28.59 28.59 0 0028.59-28.59V65.71a28.59 28.59 0 00-28.59-28.59z\" fill=\"url(#linear-gradient)\"/><path d=\"M141.52 7.42A10.31 10.31 0 01152 17.79v26.76h98a21.18 21.18 0 0121.1 21.16v115a21.19 21.19 0 01-21.17 21.17H152v26.75A10.31 10.31 0 01141.52 239a10.11 10.11 0 01-6.61-2.5L11.06 131.06a10.33 10.33 0 010-15.74L134.91 9.92a10.16 10.16 0 016.61-2.5m0-3.3a13.33 13.33 0 00-8.74 3.29L8.92 112.8a13.63 13.63 0 000 20.77L132.78 239a13.37 13.37 0 008.74 3.28 13.57 13.57 0 0013.73-13.67v-23.48h94.68a24.5 24.5 0 0024.47-24.47V65.71a24.49 24.49 0 00-24.47-24.46h-94.68V17.79a13.57 13.57 0 00-13.73-13.67z\" fill=\"url(#linear-gradient-2)\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "-10 -10 305 265"
    }
  };
});