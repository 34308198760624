(function() {
  /**
   * Ensure jQuery is available as an AMD-compatible module.
   *
   * Some Caper interactives depend on the version of jQuery provided by
   * `@ember/jquery`, and they fail to boot if it's not in AMD format.
   *
   * jQuery is provided by the `@ember/jquery`, which shims jQuery to be in ES
   * Module format, breaking AMD compatibility.
   *
   * See https://github.com/blake-education/frontend/pull/5247.
   */
  function vendorModule() {
    'use strict'
    return self['jQuery']
  }

  define('jquery', [], vendorModule)
})()
