define("ember-metrics/metrics-adapters/google-tag-manager", ["exports", "@ember/debug", "@ember/string", "ember-metrics/-private/utils/object-transforms", "ember-metrics/-private/utils/remove-from-dom", "ember-metrics/metrics-adapters/base"], function (_exports, _debug, _string, _objectTransforms, _removeFromDom, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  class GoogleTagManager extends _base.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "dataLayer", 'dataLayer');
    }
    toStringExtension() {
      return 'GoogleTagManager';
    }

    // eslint-disable-next-line ember/classic-decorator-hooks
    init() {
      const {
        id,
        dataLayer,
        envParams
      } = this.config;
      const envParamsString = envParams ? "&".concat(envParams) : '';
      (false && !(id) && (0, _debug.assert)("[ember-metrics] You must pass a valid `id` to the ".concat(this.toString(), " adapter"), id));
      this.dataLayer = dataLayer || 'dataLayer';
      this._injectScript(id, envParamsString);
    }

    // prettier-ignore
    _injectScript(id, envParamsString) {
      (function (w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({
          'gtm.start': new Date().getTime(),
          event: 'gtm.js'
        });
        var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l !== 'dataLayer' ? '&l=' + l : '';
        j.async = true;
        j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl + envParamsString;
        f.parentNode.insertBefore(j, f);
      })(window, document, 'script', this.dataLayer, id);
    }
    trackEvent() {
      let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      const compactedOptions = (0, _objectTransforms.compact)(options);
      const dataLayer = this.dataLayer;
      const gtmEvent = {
        event: compactedOptions['event']
      };
      (false && !(false) && (0, _debug.deprecate)('Future versions of the GoogleTagManagerAdapter will no longer prefix top-level dataLayer keys with `event`. If you wish to retain this behaviour you will need to override the adapter and prefix the keys yourself.', false, {
        id: 'ember-metrics.issue-438',
        for: 'ember-metrics',
        since: '1.5.0',
        until: '2.0.0'
      }));
      delete compactedOptions['event'];
      for (let key in compactedOptions) {
        const capitalizedKey = (0, _string.capitalize)(key);
        gtmEvent["event".concat(capitalizedKey)] = compactedOptions[key];
      }
      window[dataLayer].push(gtmEvent);
      return gtmEvent;
    }
    trackPage() {
      let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      const compactedOptions = (0, _objectTransforms.compact)(options);
      const dataLayer = this.dataLayer;
      const sendEvent = {
        event: compactedOptions['event'] || 'pageview'
      };
      const pageEvent = {
        ...sendEvent,
        ...compactedOptions
      };
      window[dataLayer].push(pageEvent);
      return pageEvent;
    }
    willDestroy() {
      (0, _removeFromDom.default)('script[src*="gtm.js"]');
      delete window.dataLayer;
    }
  }
  _exports.default = GoogleTagManager;
});