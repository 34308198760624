define("ember-svg-jar/inlined/login-button-click", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<rect x=\"5\" y=\"6\" width=\"250\" height=\"100\" rx=\"50\" fill=\"#C3F3FF\"/><rect x=\"5\" y=\"6\" width=\"250\" height=\"100\" rx=\"50\" stroke=\"#00BFEF\" stroke-width=\"10\"/><path d=\"M57.987 68.916V34.32c0-3.1 1.984-4.836 4.65-4.836 2.666 0 4.65 1.736 4.65 4.836v31.124h11.036c3.162 0 4.526 2.356 4.464 4.464C82.663 71.954 81.05 74 78.323 74h-15.5c-3.038 0-4.836-1.984-4.836-5.084zm42.084-28.644c9.424 0 15.996 8.246 15.996 17.298 0 9.3-6.386 17.174-15.996 17.174S84.075 66.87 84.075 57.57c0-9.052 6.572-17.298 15.996-17.298zm0 8.184c-4.898 0-7.068 4.836-7.068 9.114 0 4.278 2.232 8.99 7.068 8.99 4.836 0 7.068-4.712 7.068-8.99 0-4.278-2.17-9.114-7.068-9.114zm51.501-2.852v25.792c0 10.726-7.006 16.12-17.174 16.12-3.658 0-14.074-1.798-14.074-6.696 0-1.674 1.86-3.906 3.596-3.906 2.852 0 6.014 2.79 11.16 2.79 4.34 0 7.564-2.542 7.564-7.068v-2.108h-.124c-1.86 2.728-4.898 4.216-8.99 4.216-9.362 0-13.764-8.246-13.764-17.112 0-8.99 5.704-17.36 14.694-17.36 3.038 0 6.51 1.364 8.184 4.092.558-2.356 1.984-3.72 4.464-3.72 2.604 0 4.464 1.984 4.464 4.96zm-8.928 12.152c0-4.34-2.108-9.3-7.006-9.3-4.65 0-6.944 4.836-6.944 8.99 0 4.774 2.294 9.114 6.944 9.114 4.774 0 7.006-4.464 7.006-8.804zm15.142 11.656V45.604c0-2.976 1.86-4.96 4.464-4.96s4.464 1.984 4.464 4.96v23.808c0 2.976-1.86 4.96-4.464 4.96s-4.464-1.984-4.464-4.96zm4.464-42.16c2.666 0 5.022 2.294 5.022 4.898 0 2.79-2.232 5.146-5.022 5.146-2.666 0-5.022-2.418-5.022-5.146 0-2.542 2.356-4.898 5.022-4.898zm10.644 42.16V45.604c0-2.976 1.86-4.96 4.464-4.96 2.294 0 3.782 1.24 4.092 3.286 1.736-2.418 4.402-3.658 8.618-3.658 7.006 0 12.214 4.464 12.214 12.028v17.112c0 2.976-1.86 4.96-4.464 4.96s-4.464-1.984-4.464-4.96V53.85c0-4.154-2.604-6.138-5.828-6.138-3.658 0-5.704 2.666-5.704 6.138v15.562c0 2.976-1.86 4.96-4.464 4.96s-4.464-1.984-4.464-4.96z\" fill=\"#6B36BA\"/>",
    "attrs": {
      "width": "260",
      "height": "111",
      "viewBox": "0 0 260 111",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});