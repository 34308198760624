define("ember-component-css/pod-names", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "activity-header": "__8e73c",
    "book-shelf": "__9178f",
    "content-interactive": "__3b2f0",
    "content-interactive/debug": "__fb886",
    "debug-app/complete-activity-quiz": "__c0658",
    "debug-app/complete-activity": "__dc477",
    "debug-app/complete-map-quiz": "__51b29",
    "debug-app/native-datetime-selector": "__d876f",
    "footer": "__9235d",
    "full-page-alert": "__e43bc",
    "full-viewport": "__c308f",
    "home-nav/nav-item-egg": "__207b7",
    "home-nav/nav-item-reggie": "__d6a3e",
    "my-program/block-grid": "__9b0ae",
    "my-program/block": "__06ed3",
    "my-program/book-cover": "__e2374",
    "my-program/books-read": "__7c27c",
    "my-program/books-to-read": "__5eee5",
    "navigation-circles": "__f0e9a",
    "offline-screen": "__151e2",
    "ui-button": "__fd7ee"
  };
});