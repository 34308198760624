define("ember-svg-jar/inlined/banner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<linearGradient id=\"a\"><stop offset=\".039\" stop-color=\"#50da11\"/><stop offset=\"1\" stop-color=\"#00ab00\"/></linearGradient><linearGradient id=\"b\" gradientTransform=\"matrix(1 0 0 -1 0 1082)\" gradientUnits=\"userSpaceOnUse\" x1=\"669.456\" x2=\"601.821\" xlink:href=\"#a\" y1=\"995.255\" y2=\"976.745\"/><linearGradient id=\"c\" gradientTransform=\"matrix(1 0 0 -1 0 1082)\" gradientUnits=\"userSpaceOnUse\" x1=\"54.387\" x2=\"122.734\" xlink:href=\"#a\" y1=\"992.13\" y2=\"977.891\"/><linearGradient id=\"d\" gradientTransform=\"matrix(1 0 0 -1 0 1082)\" gradientUnits=\"userSpaceOnUse\" x1=\"336.58\" x2=\"374.535\" y1=\"1130.727\" y2=\"922.463\"><stop offset=\".145\" stop-color=\"#bcff27\"/><stop offset=\"1\" stop-color=\"#00bf00\"/></linearGradient><path d=\"M556.7 164.1h160.8l-60.8-68.5 60.8-68.5H556.7z\" fill=\"url(#b)\"/><path d=\"M556.7 43.1h146l7.4-8.3H556.7zM556.7 147.5h146l7.4 8.3H556.7z\" fill=\"#ffff4b\" opacity=\".12\"/><path d=\"M556.7 164.1v-66l48.9 44.2z\" fill=\"#007600\"/><path d=\"M160.9 163.8H0l60.8-68.5L0 26.8h160.9z\" fill=\"url(#c)\"/><path d=\"M160.9 43.1H14.8l-7.4-8.3h153.5zM160.9 147.5H14.8l-7.4 8.3h153.5z\" fill=\"#ffff4b\" opacity=\".12\"/><path d=\"M160.9 163.8v-66l-49 44.2z\" fill=\"#007600\"/><path d=\"M111.9 0h493v142h-493z\" fill=\"url(#d)\"/><g fill=\"#ffff4b\"><path d=\"M111.9 8.4h493v7.4h-493zM111.9 123.7h493v7.4h-493z\" opacity=\".2\"/></g>",
    "attrs": {
      "viewBox": "0 0 717.5 164.1",
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink"
    }
  };
});