define("@ember-data/store/-private", ["exports", "@ember-data/store/index-cc461d33"], function (_exports, _indexCc461d) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "AdapterPopulatedRecordArray", {
    enumerable: true,
    get: function () {
      return _indexCc461d.h;
    }
  });
  Object.defineProperty(_exports, "CacheHandler", {
    enumerable: true,
    get: function () {
      return _indexCc461d.C;
    }
  });
  Object.defineProperty(_exports, "IDENTIFIER_ARRAY_TAG", {
    enumerable: true,
    get: function () {
      return _indexCc461d.l;
    }
  });
  Object.defineProperty(_exports, "IdentifierArray", {
    enumerable: true,
    get: function () {
      return _indexCc461d.I;
    }
  });
  Object.defineProperty(_exports, "MUTATE", {
    enumerable: true,
    get: function () {
      return _indexCc461d.M;
    }
  });
  Object.defineProperty(_exports, "RecordArray", {
    enumerable: true,
    get: function () {
      return _indexCc461d.I;
    }
  });
  Object.defineProperty(_exports, "RecordArrayManager", {
    enumerable: true,
    get: function () {
      return _indexCc461d.R;
    }
  });
  Object.defineProperty(_exports, "SOURCE", {
    enumerable: true,
    get: function () {
      return _indexCc461d.k;
    }
  });
  Object.defineProperty(_exports, "Store", {
    enumerable: true,
    get: function () {
      return _indexCc461d.S;
    }
  });
  Object.defineProperty(_exports, "_clearCaches", {
    enumerable: true,
    get: function () {
      return _indexCc461d._;
    }
  });
  Object.defineProperty(_exports, "coerceId", {
    enumerable: true,
    get: function () {
      return _indexCc461d.f;
    }
  });
  Object.defineProperty(_exports, "constructResource", {
    enumerable: true,
    get: function () {
      return _indexCc461d.e;
    }
  });
  Object.defineProperty(_exports, "ensureStringId", {
    enumerable: true,
    get: function () {
      return _indexCc461d.g;
    }
  });
  Object.defineProperty(_exports, "fastPush", {
    enumerable: true,
    get: function () {
      return _indexCc461d.m;
    }
  });
  Object.defineProperty(_exports, "isStableIdentifier", {
    enumerable: true,
    get: function () {
      return _indexCc461d.i;
    }
  });
  Object.defineProperty(_exports, "normalizeModelName", {
    enumerable: true,
    get: function () {
      return _indexCc461d.n;
    }
  });
  Object.defineProperty(_exports, "notifyArray", {
    enumerable: true,
    get: function () {
      return _indexCc461d.j;
    }
  });
  Object.defineProperty(_exports, "peekCache", {
    enumerable: true,
    get: function () {
      return _indexCc461d.p;
    }
  });
  Object.defineProperty(_exports, "recordIdentifierFor", {
    enumerable: true,
    get: function () {
      return _indexCc461d.r;
    }
  });
  Object.defineProperty(_exports, "removeRecordDataFor", {
    enumerable: true,
    get: function () {
      return _indexCc461d.o;
    }
  });
  Object.defineProperty(_exports, "setIdentifierForgetMethod", {
    enumerable: true,
    get: function () {
      return _indexCc461d.c;
    }
  });
  Object.defineProperty(_exports, "setIdentifierGenerationMethod", {
    enumerable: true,
    get: function () {
      return _indexCc461d.a;
    }
  });
  Object.defineProperty(_exports, "setIdentifierResetMethod", {
    enumerable: true,
    get: function () {
      return _indexCc461d.d;
    }
  });
  Object.defineProperty(_exports, "setIdentifierUpdateMethod", {
    enumerable: true,
    get: function () {
      return _indexCc461d.b;
    }
  });
  Object.defineProperty(_exports, "storeFor", {
    enumerable: true,
    get: function () {
      return _indexCc461d.s;
    }
  });
});