define("ember-svg-jar/inlined/button-avatar-re-nav", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g filter=\"url(#filter0_d_304_17332)\"><circle cx=\"60\" cy=\"60\" r=\"58\" fill=\"#01AFEF\" stroke=\"#fff\" stroke-width=\"4\"/><circle cx=\"60\" cy=\"60\" r=\"48.612\" fill=\"#ADB3E9\"/><circle cx=\"60\" cy=\"60\" r=\"49.433\" fill=\"url(#paint0_linear_304_17332)\"/><ellipse cx=\"36.198\" cy=\"22.738\" rx=\"8.563\" ry=\"3.253\" transform=\"rotate(-30.811 36.198 22.738)\" fill=\"#fff\" fill-opacity=\".6\"/><ellipse cx=\"26.463\" cy=\"31.672\" rx=\"3.572\" ry=\"2.608\" transform=\"rotate(-30.811 26.463 31.672)\" fill=\"#fff\" fill-opacity=\".6\"/><path d=\"M57.034 8.152C46.159 9.218 36.272 13.483 30.34 18.815c-9.886 8.531-19.773 23.46-19.773 40.522 31.103 23.46 74.248-14.524 98.866 1.109v-1.43c-2.966-34.87-27.683-51.93-52.4-50.864z\" fill=\"url(#paint1_linear_304_17332)\" fill-opacity=\".5\"/></g><defs><linearGradient id=\"paint0_linear_304_17332\" x1=\"78.75\" y1=\"10.567\" x2=\"40.113\" y2=\"109.433\" gradientUnits=\"userSpaceOnUse\"><stop stop-color=\"#B4EBFF\"/><stop offset=\"1\" stop-color=\"#B3E6F9\"/></linearGradient><linearGradient id=\"paint1_linear_304_17332\" x1=\"71.211\" y1=\"67.356\" x2=\"71.211\" y2=\"10.37\" gradientUnits=\"userSpaceOnUse\"><stop stop-color=\"#fff\" stop-opacity=\".6\"/><stop offset=\"1\" stop-color=\"#fff\" stop-opacity=\"0\"/></linearGradient><filter id=\"filter0_d_304_17332\" x=\"0\" y=\"0\" width=\"120\" height=\"125\" filterUnits=\"userSpaceOnUse\" color-interpolation-filters=\"sRGB\"><feFlood flood-opacity=\"0\" result=\"BackgroundImageFix\"/><feColorMatrix in=\"SourceAlpha\" values=\"0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0\" result=\"hardAlpha\"/><feOffset dy=\"5\"/><feComposite in2=\"hardAlpha\" operator=\"out\"/><feColorMatrix values=\"0 0 0 0 0.0604894 0 0 0 0 0.363768 0 0 0 0 0.776367 0 0 0 0.8 0\"/><feBlend in2=\"BackgroundImageFix\" result=\"effect1_dropShadow_304_17332\"/><feBlend in=\"SourceGraphic\" in2=\"effect1_dropShadow_304_17332\" result=\"shape\"/></filter></defs>",
    "attrs": {
      "width": "120",
      "height": "125",
      "viewBox": "0 0 120 125",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});